/* General styling for larger screens */
.calendar-container {
    padding: 20px;
    height: auto;
    min-height: 100vh;
}

.calendar {
    border: 1px solid #ddd;
    width: 100%;
    max-width: 900px;
    height: auto;
}

.calendar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #1976d2;
    color: #fff;
    padding: 10px;
    font-size: 18px;
    border-bottom: 1px solid #ddd;
}

.calendar-header button {
    background-color: transparent;
    color: #fff;
    border: none;
    cursor: pointer;
    font-size: 14px;
    outline: none;
}

.calendar-header button:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.calendar-header select {
    background-color: #1976d2;
    color: #fff;
    border: none;
    padding: 5px;
    font-size: 16px;
    outline: none;
}

.calendar-days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 1px;
}

.calendar-day {
    padding: 20px 0px;
    text-align: center;
    cursor: pointer;
    user-select: none;
    transition: background-color 0.3s;
}

.calendar-day:hover {
    background-color: #f0f0f0;
}

.calendar-day.selected {
    background-color: #1976d2 !important;
    color: #fff;
}

.calendar-day.has-events {
    border: 2px solid #27ae60;
}

.calendar-day.todays-date {
    border: 2px solid #EE6352;
}

.empty-day {
    background-color: #eee;
}

.event-list-container {
    display: flex;
    justify-content: space-around;
}

.event-list {
    width: 100%;
    max-width: 900px;
    margin-top: 20px;
    padding: 10px;
}

.event-list h2 {
    color: #333;
    margin-bottom: 10px;
}

.event-list p {
    margin: 5px 0;
    color: #555;
}

/* Modern styling for dropdown */
.calendar-header select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-right: 20px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23ffffff' width='18px' height='18px'%3E%3Cpath d='M7 10l5 5 5-5z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center right;
}

.day-of-week {
    padding: 10px;
    text-align: center;
    font-weight: bold;
    color: #333;
}

/* Responsive styles for smaller screens */
@media screen and (max-width: 600px) {
    .calendar-container {
        padding: 10px;
    }

    .calendar-days {
        grid-template-columns: repeat(7, 1fr);
        gap: 1px;
    }

    .calendar-day {
        padding: 15px 0;
    }
}
